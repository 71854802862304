// USA
export const locale = {
    TRANSLATOR: {
        SELECT: "Киргизүү тилин тандаңыз"
    },
    MENU: {
        SCHOOL: "Мектеп",
        SCHOOLS: "Мектептер",
        NEW: "Жаңы",
        ACTIONS: "Иш-аракет",
        CREATE_POST: "Пост түзүү",
        PAGES: "Барактар",
        FEATURES: "Мүмкүнчүлүктөр",
        APPS: "Тиркемелер",
        DASHBOARD: "Жумуш столу",
        TEACHERS: "Мугалимдер",
        STUDENT_GROUPS: "Класстар",
        ALL_SCHOOL_STUDENTS: "Окуучулар",
        ALL_SCHOOL_MARKS: "Баалар",
        MY_SCHOOL: " Менин мектебим ",
        MY_GROUP: " Менин классым",
        EDUCATION_PLAN: "Окуу планы",
        CLASSROOM_TEACHER: " Класс жетекчилиги",
        TEACHER_SUBJECTS: "Предметтер",
        TIME_TABLE: "Күн тартиби",
        TIME_TABLE_FOR_SCHOOL: " Мектеп үчүн күн тартиби",
        STUDENT_QUARTER_MARKS: " Чейректик баалар",
        SCHOOL_PAGES: "Барактар",
        CERTIFICATES: "Сертификаттар",
        SCHOOL_PROGRAM: "Мектеп программасы",
        ABSENCE_LOG_REPORT: "Катышуу боюнча маалыматы",
        ABSENCE_LOG_REPORT_SCHOOL: " Мектепке катышуу боюнча маалыматы",
        ABSENCE_LOG_REPORT_PRESCHOOL: "МЧББУга катышуу боюнча маалымат",
        /**/
        EDU_DEPARTMENT_SCHOOLS: "Мектептер",
        EDU_DEPARTMENT_TEACHERS: "Мугалимдер",
        EDU_DEPARTMENT_STUDENTS: "Окуучулар",
        /*report*/
        REPORTS: "Отчеттор",
        REPORTS_EDU_PROGRESS: "1-11 класстын окуучулардын жетишкендиктери жана билим сапаты жонүндө отчет",
        REPORTS_EDU_PROGRESS_BY_SUBJECT: "Окуучулардын предметтер боюнча билиминин сапаты жана жетишкендиктери жөнүндө отчет",
        REPORTS_EDU_PROGRESS_BY_TEACHER: "Мугалимдер боюнча окуучулардын жетишкендиктери жана билим сапаты жөнүндө отчет",
        REPORTS_SCHOOL_SOCIAL_PASSPORT: " Мектептин социалдык паспорту",
        REPORTS_SCHOOL_SUMMARY_GROUP_PROGRESS_REPORT: "Жетишүүнү эсепке алуунун жыйынтык ведомосту",
        REPORTS_SCHOOL_FINAL_MARKS_COUNT_REPORT: "Жыйынтыктоочу баалар жөнүндө маалымат",
        REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_MOVEMENT: "Окуучулардын кыймылы жөнүндө маалымат",
        REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_LEAVING: "Окуучулардын мектептен чыгуусунун себептери жөнүндө маалымат",
        REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_ABSENCE: "Окуучулар өткөрүп жиберген күндөрдүн жана сабактардын саны жөнүндө маалымат",
        REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_MOVEMENT_ALL: "Окуучулардын кыймылы жөнүндө маалымат 1-11 класстар",
        /**/
        PRESCHOOL_COMMON_NAME: "МЧББУ",
        PRESCHOOL_KINDERGARTNERS: "Тарбиячылар",
        PRESCHOOL_GROUPS: "Топтор",
        NURSE_HEALTH_PAGE: "Окуучунун ден соолугу тууралуу маалыматтар",
        /*Infrastructure*/
        SCHOOL_INFRASTRUCTURE_CLASSROOMS: "Кабинет фонду",
        /**/
        SCHOOL_TIME_TABLE: "Автографик",
        /**/
        SCHOOL_TRANSFER_LIST: "Которуу",
    },
    AUTH: {
        GENERAL: {
            OR: "же болбосо",
            SUBMIT_BUTTON: "Жөнөтүү",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Сыр сөздү унутуп калдым",
            BACK_BUTTON: " Артка",
            PRIVACY: "Купуялуулук",
            LEGAL: "Legal",
            CONTACT: "Байланыш"
        },
        LOGIN: {
            TITLE: "Кабинетке кирүү",
            BUTTON: "Кирүү"
        },
        FORGOT: {
            TITLE: "Сыр сөздү унутуп калдыңызбы?",
            DESC: "Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: "Selected records count: ",
            ALL: "All",
            SUSPENDED: "Suspended",
            ACTIVE: "Active",
            FILTER: "Filter",
            BY_STATUS: "by Status",
            BY_TYPE: "by Type",
            BUSINESS: "Business",
            INDIVIDUAL: "Individual",
            SEARCH: "Search",
            IN_ALL_FIELDS: "in all fields"
        },
        ECOMMERCE: "eCommerce",
        CUSTOMERS: {
            CUSTOMERS: "Customers",
            CUSTOMERS_LIST: "Customers list",
            NEW_CUSTOMER: "New Customer",
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: "Customer Delete",
                DESCRIPTION: "Are you sure to permanently delete this customer?",
                WAIT_DESCRIPTION: "Customer is deleting...",
                MESSAGE: "Customer has been deleted"
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: "Customers Delete",
                DESCRIPTION: "Are you sure to permanently delete selected customers?",
                WAIT_DESCRIPTION: "Customers are deleting...",
                MESSAGE: "Selected customers have been deleted"
            },
            UPDATE_STATUS: {
                TITLE: "Status has been updated for selected customers",
                MESSAGE: "Selected customers status have successfully been updated"
            },
            EDIT: {
                UPDATE_MESSAGE: "Customer has been updated",
                ADD_MESSAGE: "Customer has been created"
            }
        }
    },
    common: {
        new: "Жаңы",
        delete: "Алып салуу",
        save: "Сактоо",
        cancel: "Айынуу",
        edit: "Өзгөртүү",
        close: "Жабуу",
        filter: "Издөө",
        importStudents: "Окуучуларды импорттоо",
        importTeachers: "Мугалимдерди импорттоо",
        sex: {
            male: 'Эркек',
            female: 'Аял',
        },
        move: "Которуу",
        add: "Кошуу",
        export: "Экспорттоо",
        exportPasswords: "Сырдык сөздү экспорттоо",
        showDroppedStudents: "Кеткен окуучуларды көрсөтүү"
    },
    schools: {
        title: "Мектеп",
        list: {
            nameRu: "Орусча аталышы",
            nameKg: "Кыргызча аталышы",
            address: "Дарек",
            region: "Район",
            contactPhone: "Телефон Номуру ",
            latitude: "Кеңдик",
            longitude: "Узундук",
            image: "Сүрөт",
            foundYear: " Билим алган жылы",
            studentsCount: " Окуучулардын саны",
            graduatesPerYear: "Жылына бүтүрүүчүлөр",
            booksInTheLibrary: "Китепканадагы китептердин саны",
            shortNameRu: "Орусча кыска аталышы",
            shortNameKg: "Кыргызча кыска аталышы",
            number: "Номур",
            siteKey: "Сайт",
        }
    },
    student: {
        dashboard: "Күндөлүк",
    },
    teacher: {
        timeTable: "Күн тартиби"
    },
    marks: {
        title: "Баалар",
        homeWork: "Үй тапшырма",
        lessonContent: "Сабакта эмне өттү",
        list: {
            fio: "ФИО"
        },
        form: {
            hw: 'Домашнее задание',
            day: 'Датасы',
            number: 'Сабактын №',
        },
        lessonContentForm: {
            lessonContent: '  Сабакта эмне өттү',
            day: 'Датасы',
            number: 'Сабактын №',
        }
    },
    teachersList: {
        inn: "ИЖН",
        school: "Мектеп",
        title: "Мугалимдер",
        firstName: "Фамилиясы",
        name: "Ысымы",
        parentName: "Атасынын аты",
        contactPhone: "Телефон №",
        role: "Ролу",
        userName: "Логин",
        password: "Сыр сөз",
        birthDate: "Туулган жылы",
    },
    studentGroups: {
        title: "Класстар",
        list: {
            name: "Аталышы",
            level: "Параллель",
            year: "Окуу жылы",
            shift: "Смена",
            classroomTeacher: "Класс жетекчиси",
        },
        moveToNextYear: "Кийинки жылга которуу",
        move: {
            newGroupName: "Класстын жаңы аталышы"
        }
    },
    studentsList: {
        inn: "ИЖН",
        schoolName: "Мектеп",
        title: "Окуучулар",
        firstName: "Фамилиясы",
        name: "Ысымы",
        patronymic: "Атасын аты",
        parentName: "Ата-эне",
        contactPhone: "Телефон №",
        userName: "Логин",
        password: "Сыр сөз",
        socialPassportCategory: "Соц категориясы",
        birthDate: "Туулган жылы",
        sex: "Жынысы",
        drop: {
            button: "Чыгуу",
            title: "Чыгуу",
            form: {
                date: "Чыгуу датасы",
                reason: "Чыгуу себеби"
            }
        },
        fullName: "Толук аты",
        arrivingDate: "Келген датасы",
        endDate: "Кеткен датасы",
    },
    groupSubjects: {
        title: "Предметтер",
        list: {
            group: "Класс",
            subject: "Предметтер",
            name: "Кыскача аталышы",
            teacher: "Мугалим",
            classWorkPerWeekHours: " Жумасына сабактардын саны",
            roomNumber: "Кабинеттин №",
            roomNumberAdditional: "Кошумча кабинеттин №",
            roomNumberAdditional2: "Экинчи кошумча кабинеттин №",
        }
    },
    timeTable: {
        title: "Күн тартиби",
        day: {
            monday: "Дш",
            tuesday: "Шб",
            wednesday: "Шр",
            thursday: "Бш",
            friday: "Ж",
            saturday: "Иш",
        },
        roomNumber: 'Кабинет',
        copyFromPrevWeek: 'Мурунку жуманын графигин көчүрүү',
        copyFromNextWeek: 'Кийинки менен графикти көчүрүңүз',
        moveDay: "Күндү тандаңыз",
        moveDayItem: " Сабак тандаңыз"
    },
    videoLesson: {
        title: "Видео сабак"
    },
    studentTimeTable: {
        day1: "Дүйшөнбү",
        day2: "Шейшенби",
        day3: "Шаршемби",
        day4: "Бейшемби",
        day5: "Жума",
        day6: "Ишемби"
    },
    my: {
        school: "Менин мектебим",
        group: "Менин классым",
    },
    educationPlan: {
        title: 'Окутуунун пландары',
        name: 'Аталышы',
        subjectName: 'Предмет',
        level: 'Класстар үчүн',
        eduHours: 'Окуу сааттары',
        teacherName: 'Мугалим',
        subject: 'Предмет',
    },
    educationPlanItem: {
        theme: 'Темасы',
        lessonContent: 'Сабакта эмне өттү',
        educationHours: 'Окуу сааттары',
        homeWork: 'Үй тапшырма',
        lessonType: 'Сабактын типи',
        competencies: 'Компетенции',
        literature: 'Литература',
        zun: 'Зун',
        note: 'Эскертүү',
        additionalMaterials: 'Кошумча материалдар',
        additionalMaterial:{
            type: "Түрү",
            name: "Аты",
            value: "Мааниси",
            file: "Файл",
        }
    },
    classroomTeacher: {
        title: 'Класс жетекчилиги'
    },
    subGroups: {
        name: 'Кыскача аталышы',
        group: 'Класс',
        subject: 'Предмет',
        title: 'Кичи топтор',
        new: 'Жаңы кичи топторду кошуңуз',
        teacher: 'Мугалим',
        studentFullName: 'Аты-жөнү',
    },
    gradeBook: {
        title: 'Бир жумага журнал'
    },
    quarterMarks: {
        title: 'Чейрек баалары',
        quarter: 'Чейрек',
        annualMark: 'Жылдык баа',
        examinationMarks: 'Экзамендин баасы',
        finalMarks: ' Жыйынтыктоочу баа',
    },
    schoolPages: {
        title: "Барактар",
        list: {
            type: "Түрү",
            date: "Датасы",
            titleKg: "Кыргызча аталышы",
            titleRu: "Орусча аталышы",
            contentKg: "Мазмуну кыргыз тилинде",
            contentRu: "Мазмуну орус тилинде",
            mainImage: "Башкы сүрөт"
        }
    },
    certificates: {
        title: "Сертификаттар",
        list: {
            id: "Id",
            image: "Сүрөт"
        }
    },
    studentInfo: {
        title: 'Окуучунун маалыматтары',
        data: {
            inn: 'ИЖН',
            firstName: 'Фамилиясы',
            name: 'Ысымы',
            patronymic: 'Атасын аты',
            birthDate: 'Туулган жылы',
            sex: 'Жынысы',
            birthCertificateNumber: ' Туулгандыгы тууралуу күбөлүк',
            citizenshipId: 'Жарандык',
            socialPassportCategoryId: 'Соц категориясы',
            nationalityId: 'Улуту',
            livingAddress: 'Дареги',
            phoneNumber: 'Телефон №',
            whatsApp: 'WhatsApp',
            cartNumber: 'Өздүк делонун №',
        },
        parents: {
            title: 'Ата-эне',
            list: {
                fullName: 'Ысымы',
                phoneNumber: 'Телефон №',
                whatsAppNumber: 'whatsApp',
            },
            form: {
                inn: 'ИЖН',
                firstName: 'Фамилиясы',
                name: 'Ысымы',
                patronymic: 'Атасын аты',
                address: 'Дареги',
                sex: 'Жынысы',
                phoneNumber: 'Телефон №',
                whatsAppNumber: 'WhatsApp',
                workOrganizationName: 'Иштеген жери',
                workPosition: 'Кызмат орду',
                workAddress: 'Иштеген жеринин дареги',
            }
        }
    },
    informationAboutBooks: {
        title: 'Окуу китептери тууралуу маалымат',
        list: {
            name: 'Окуу китептердин аталышы',
            firstHalfSuppliedBySchool: 'Мектеп китепканасында алынган окуу китептердин саны',
            firstHalfSuppliedByParents: 'Ата-энелер жана башкалар тарабынан алынган окуу китептеринин саны',
            firstHalfSupplyRate: ' Камсыз кылуу %',
            secondHalfSuppliedBySchool: 'Мектеп китепканасында алынган окуу китептердин саны',
            secondHalfSuppliedByParents: 'Ата-энелер жана башкалар тарабынан алынган окуу китептеринин саны',
            secondHalfSupplyRate: 'Камсыз кылуу %',
        },
    },
    socialUsefulWork: {
        title: 'Коомдук пайдалуу эмгек',
        list: {
            name: 'Аты-жөнү',
            shortDescription: 'Аткарылган коомдук пайдалуу эмгектин кыскача баяндамасы',
            socUsefulWorkFirstHalf: 'I жарым жылдык',
            socUsefulWorkSecondHalf: 'II жарым жылдык',
            socUsefulWorkContent: 'Коомдук тапшырманын мүнөзү',
        },
    },
    informationAboutClassesInClub: {
        title: 'Сабактар жана ийримдер жөнүндө маалымат',
        list: {
            name: 'Аты-жөнү',
            clubs: 'Ийримдер(секциялар, клубдар)',
            elective: 'Факультативдер',
            clubNameFirstHalf: 'Ийримдердин (секциялардын, клубдардын) аталышы',
            clubOrgNameFirstHalf: 'Ал уюштурулган мекеменин аталышы',
            clubNameSecondHalf: 'Ийримдин(секциянын, клубдун) аталышы',
            clubOrgNameSecondHalf: 'Ал уюштурулган мекеменин аталышы',
            electiveNameFirstHalf: 'Факультативдик курстун аталышы',
            electiveNameSecondHalf: 'Факультативдик курстун аталышы'
        },
    },
    informationAboutPhysicalActivityIndicators: {
        title: 'Окуучунун физикалык активдүүлүгү',
        list: {
            name: 'Аты-жөнү',
            firstHalf: 'Окуу жылынын башында (сентябрь)',
            secondHalf: 'Окуу жылынын аягында (май)',

            phyActIndicatorBeginRun: '60 м чуркоо',
            phyActIndicatorBeginPullUp: 'Тартуу (балдар), Денени көтөрүү(кыздар)',
            phyActIndicatorBeginStandingLongJump: 'Узундукка секирүү (см)',
            phyActIndicatorBeginMark: 'Физикалык даяндыгынын абалын баалоо',

            phyActIndicatorEndRun: '60 м чуркоо',
            phyActIndicatorEndPullUp: 'Тартуу (балдар), Денени көтөрүү(кыздар)',
            phyActIndicatorEndStandingLongJump: 'Узундукка секирүү (см)',
            phyActIndicatorEndMark: 'Физикалык даяндыгынын абалын баалоо',
        },
    },
    informationAboutHeath: {
        title: 'Окуучунун ден соолугу тууралуу маалыматтар',
        list: {
            name: 'Аты-жөнү',
            birthDate: 'Туулган жылы',
            healthData: 'Окуучунун ден соолугу тууралуу маалыматтар',

            diagnosisOfTheDisease: 'Оорунун диагнозу',
            treatmentRecommendations: 'Дарылоо сунуштары',
            vision: 'Көрүү',
            hearing: 'Угуу',
            medicalPhysicalEduGroup: 'Медфизкультура тобу',

            height: 'Бою (см)',
            deskNumber: 'Партанын №'
        },
    },
    summaryReportOfStudentsProgress: {
        title: "Окуучулардын жетишүүсүн эсепке алуунун жыйынтык ведомосту",
        teachersCouncilDecision: " Педсоветтин чечими (датасы, №)",
        quarter: {
            "1": "1 чейрек",
            "2": "2 чейрек",
            "3": "3 чейрек",
            "4": "4 чейрек",
            "5": "Жылдык баа",
            "6": " Экзамендин баасы",
            "7": "Жыйынтыктоочу баа",
        }
    },
    groupFinalMarksCountReport: {
        title: "Жыйынтыктоочу баалар жөнүндө маалымат",
        teachersCouncilDecision: "Педсоветтин чечими (датасы, №)",
        marks: "Оценки",
        quarter: {
            "1": "1 чейрек",
            "2": "2 чейрек",
            "3": "3 чейрек",
            "4": "4 чейрек",
            "5": "Жылдык баа",
            "6": " Экзамендин баасы",
            "7": "Жыйынтыктоочу баа",
        }
    },
    groupInformationAboutStudentMovementReport: {
        title: "Окуучулардын кыймылы жөнүндө маалымат",
        quarterStartCount: "Чейрек башындагы окуучулардын саны",
        quarterStartCountKyrgyz: "Анын ичинде кыргыздар",
        quarterStartCountGirls: "Алардын ичинен кыздар",

        leaveDuringQuarterCount: "Чейрек ичинде окуудан чыккан окуучулардын саны",
        leaveDuringQuarterCountKyrgyz: "Анын ичинде кыргыздар",
        leaveDuringQuarterCountGirls: " Алардын ичинен кыздар",
        joinDuringQuarterCount: "Чейрек ичинде кабыл алынган окуучулардын саны",
        joinDuringQuarterCountKyrgyz: "Анын ичинде кыргыздар",
        joinDuringQuarterCountGirls: "Алардын ичинен кыздар",

        quarterEndCount: "Чейрек аягындагы окуучулардын саны",
        quarterEndCountKyrgyz: "Анын ичинде кыргыздар",
        quarterEndCountKyrgyzGirls: "Алардын ичинен кыздар",
        quarterEndCountBoys: "Жалпы балдар",
        quarterEndCountGirls: "Жалпы кыздар",

        eduDaysCount: "Класс канча күн окуду",

        quarter: {
            "1": "1 чейрек",
            "2": "2 чейрек",
            "3": "3 чейрек",
            "4": "4 чейрек",
            "yearly": "Бир жылда"
        },

    },
    informationAboutStudentLeaving: {
        title: "Окуучулардын окуудан чыгуусунун себептери жөнүндө маалыматтар",

        leavingReason: "Кетишинин себеби",
        parentsMovement: "Ата-энелеринин көчүүсү",
        transfer: "Которулган",

        outsideTheRepublic: "Республиканын чегинен тышкары",
        outsideTheOblast: "Облустун чегинен сырткары, бирок Кыргыз Республикасында",
        outsideTheRaion: "Райондун чегинен сырткары",
        insideTheRaion: "Райондун чегинде",
        school: "Орто билим берүүчү жалпы билим берүүчү мектептер",
        schoolTooFar: "Мектептен алыстыгы",
        work: "Жумушка кабыл алынды",

        homeCircumstances: "Үйдөгү жагдайлар",
        longTermIllness: "Узакка созулган оору жана өлүм",
        expulsionFromSchool: "Мектептен чыгаруу",
        otherReasons: "Башка себептер",
        all: "Жалпы кеткендер",

        quarter: {
            "1": "1 чейрек",
            "2": "2 чейрек",
            "3": "3 чейрек",
            "4": "4 чейрек",
            "yearly": "Бир жылда"
        },

    },
    /*classroom*/
    classrooms: {
        title: "Кабинеттин фонду",
        common: {
            building: "Корпус",
            classroomNumber: "Кабинеттин №",
            outOfService: "Иштебейт",
        }
    },
    /**/
    studentAbsenceNumberOfDays: {
        title: "Окуучулардын өткөрүлгөн күндөрүнүн жана сабактарынын саны жөнүндө маалымат",

        studentName: "Окуучулардын тизмеси",
        all: "Бардыгы",

        fullDays: "Толук күн",
        fullDaysByIllness: "Анын ичинде оору боюнча",

        lessons: "Сабактар",
        lessonsByIllness: "Анын ичинде оору боюнча",

        annual: "Жалпы бир жылда",


        quarter: {
            "1": "1 чейрек",
            "2": "2 чейрек",
            "3": "3 чейрек",
            "4": "4 чейрек",
            "yearly": "Жалпы бир жылда"
        },

        months: {
            "9": "Сентябрь",
            "10": "Октябрь",
            "11": "Ноябрь",
            "12": "Декабрь",
            "1": "Январь",
            "2": "Февраль",
            "3": "Март",
            "4": "Апрель",
            "5": "Май",
        }

    },
    /**/
    schoolTimeTable: {
        "title": "Күн тартиби калыптары",
        "list": {
            "name": "Аты",
            "shift": "Смена",
        },
        "details": {},
        day: {
            1: "Дш",
            2: "Шб",
            3: "Шр",
            4: "Бш",
            5: "Ж",
            6: "Иш"
        },
        applyButton: "Колдонуу",
        applyForm:{
            title: "Колдонуу",
            date: "Датасы",
        },
        clearButton: "Тазалоо"
    },
    educationProgram: {
        title: 'Окуу программасы'
    },
    callSchedule:{
        types:{
            "first":"Биринчи смена",
            "second":"Экинчи смена",
            "third":"Үчүнчү смена",
        }
    }
}

