// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Выберите язык ввода"
  },
  MENU: {
    SCHOOL: "Школа",
    SCHOOLS: "Школа",
    NEW: "Новый",
    ACTIONS: "Действия",
    CREATE_POST: "Создать пост",
    PAGES: "Страницы",
    FEATURES: "Возможности",
    APPS: "Приложения",
    DASHBOARD: "Рабочий стол",
    TEACHERS: "Преподаватели",
    STUDENT_GROUPS: "Классы",
    ALL_SCHOOL_STUDENTS: "Ученики",
    ALL_SCHOOL_MARKS: "Оценки",
    MY_SCHOOL: "Моя школа",
    MY_GROUP: "Мой класс",
    EDUCATION_PLAN: "КТП",
    CLASSROOM_TEACHER: "Классное руководство",
    TEACHER_SUBJECTS: "Предметы",
    TIME_TABLE: "Расписание",
    TIME_TABLE_FOR_SCHOOL: "Расписание для школы",
    STUDENT_QUARTER_MARKS: "Четвертные оценки",
    SCHOOL_PAGES: "Страницы",
    CERTIFICATES: "Сертификаты",
    SCHOOL_PROGRAM: "Учебная программа",
    ABSENCE_LOG_REPORT: "Информация по посещаемости",
    ABSENCE_LOG_REPORT_SCHOOL: "Информация по посещаемости школы",
    ABSENCE_LOG_REPORT_PRESCHOOL: "Информация по посещаемости ДОО",
    /**/
    EDU_DEPARTMENT_SCHOOLS: "Школы",
    EDU_DEPARTMENT_TEACHERS: "Учителя",
    EDU_DEPARTMENT_STUDENTS: "Ученики",
    /*report*/
    REPORTS: "Отчеты",
    REPORTS_EDU_PROGRESS: "Отчет об успеваемости и качеству знаний учащихся 1-11 классов",
    REPORTS_EDU_PROGRESS_BY_SUBJECT: "Отчет об успеваемости и качеству знаний учащихся по предметам",
    REPORTS_EDU_PROGRESS_BY_TEACHER: "Отчет об успеваемости и качеству знаний учащихся по преподавателям",
    REPORTS_SCHOOL_SOCIAL_PASSPORT: "Социальный паспорт школы",    
    REPORTS_SCHOOL_SUMMARY_GROUP_PROGRESS_REPORT: "Сводная ведомость учета успеваемости",    
    REPORTS_SCHOOL_FINAL_MARKS_COUNT_REPORT: "Сведения об итоговых оценках",    
    REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_MOVEMENT: "Сведения о движении учащихся",    
    REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_LEAVING: "Сведения о причинах выбытия учащихся",    
    REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_ABSENCE: "Сведения о количестве дней и уроков, пропущенных учащимся",
    REPORTS_SCHOOL_INFORMATION_ABOUT_STUDENT_MOVEMENT_ALL: "Сведения о движении учащихся 1-11 классов",

    REPORTS_STUDENT_MOVEMENT: "Движение учащихся",
    REPORTS_STUDENT_COUNT_BY_LEVEL: "Количество учащихся по классам",
    
    /**/
    PRESCHOOL_COMMON_NAME: "ДОО",
    PRESCHOOL_KINDERGARTNERS: "Воспитатели",
    PRESCHOOL_GROUPS: "Группы",
    NURSE_HEALTH_PAGE: "Данные о состоянии здоровья школьника",
    /*Infrastructure*/
    SCHOOL_INFRASTRUCTURE_CLASSROOMS: "Кабинетный фонд",
    /**/
    SCHOOL_TIME_TABLE: "Авторасписание",
    /**/
    SCHOOL_TRANSFER_LIST: "Переводы",
  },
  AUTH: {
    GENERAL: {
      OR: "Или",
      SUBMIT_BUTTON: "Отправить",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Забыли пароль",
      BACK_BUTTON: "Назад",
      PRIVACY: "Конфиденциальность",
      LEGAL: "Legal",
      CONTACT: "Контакты"
    },
    LOGIN: {
      TITLE: "Вход в кабинет",
      BUTTON: "Войти"
    },
    FORGOT: {
      TITLE: "Забыли пароль?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  common: {
    new: "Новый",
    delete: "Удалить",
    save: "Сохранить",
    cancel: "Отмена",
    edit: "Редактировать",
    close: "Закрыть",
    filter: "Поиск",
    importStudents: "Импорт студентов",
    importTeachers: "Импорт преподователей",
    sex: {
      male:'Мужской',
      female:'Женский',
    },
    move:"Перенести",
    add:"Добавить",
    export: "Экспортировать",
    exportPasswords: "Экспортировать пароли",
    showDroppedStudents: "Показать выбывших учеников"
  },
  schools: {
    title: "Школа",
    list: {
      nameRu: "Название на Русском",
      nameKg: "Название на Кыргызском",
      address: "Адрес",
      region: "Район",
      contactPhone: "Номер телефона",
      latitude: "Широта",
      longitude: "Долгота",
      image: "Изображение",
      foundYear: "Год образования",
      studentsCount: "Количество учеников",
      graduatesPerYear: "Выпускников в год",
      booksInTheLibrary: "Количество книг в библиотеке",
      shortNameRu: "Короткое название на Русском",
      shortNameKg: "Короткое название на Кыргызском",
      number: "Номер",
      siteKey: "Сайт",
    }
  },
  student:{
    dashboard: "Дневник",
  },
  teacher:{
    timeTable: "Расписание"
  },
  marks: {
    title: "Оценки",
    homeWork: "Домашнее задание",
    lessonContent: "Что пройдено на уроке",
    list: {
      fio: "ФИО",
      mark: "Оценка",
      mark2: "Вторя оценка",
      note: "Замечание",
    },
    form:{
      hw: 'Домашнее задание',
      day: 'Дата',
      number: 'Номер урока',
    },
    lessonContentForm:{
      lessonContent: 'Что пройдено на уроке',
      day: 'Дата',
      number: 'Номер урока',
    }
  },
  teachersList:{
    inn: "ИНН",
    school: "Школа",
    title: "Преподаватели",
    firstName: "Фамилия",
    name: "Имя",
    parentName: "Отчество",
    contactPhone: "Номер телефона",
    role: "Роль",
    userName: "Логин",
    password: "Пароль",
    birthDate: "Дата рождения",
  },
  studentGroups:{
    title: "Классы",
    list:{
      name: "Название",
      level: "Параллель",
      year: "Год обучения",
      shift: "Смена",
      classroomTeacher: "Классный руководитель",
    },
    moveToNextYear:"Перевести на следующий год",
    move:{
      newGroupName: "Новое название класса"
    }
  },
  studentsList:{
    inn:"Инн",
    schoolName:"Школа",
    title:"Ученики",
    firstName:"Фамилия",
    name:"Имя",
    patronymic:"Отчество",
    parentName:"Родитель",
    contactPhone:"Номер телефона",
    userName:"Логин",
    password:"Пароль",
    socialPassportCategory:"Соц категория",
    birthDate: "Дата рождения",
    sex: "Пол",
    drop: {
      button: "Выбытие",
      title: "Выбытие",
      form: {
        date: "Дата выбытие",
        reason: "Причина выбытия"
      }
    },
    fullName: "ФИО",
    arrivingDate: "Дата прибытия",
    endDate: "Дата выбытия",    
  },
  groupSubjects:{
    title:"Предметы",
    list:{
      group: "Класс",
      subject: "Предмет",
      name: "Коротокое название",
      teacher: "Преподаватель",
      classWorkPerWeekHours: "Количество уроков в неделю",
      roomNumber: "Номер кабинета",
      roomNumberAdditional: "Номер доп. кабинета",
      roomNumberAdditional2: "Номер доп. кабинета 2",
    },
    createFromEduProgramButton:'Сформирвать из учебной программы'
  },
  timeTable: {
    title: "Расписание",
    day:{
      monday: "Пон",
      tuesday: "Вт",
      wednesday: "Ср",
      thursday: "Чет",
      friday: "Пят",
      saturday: "Суб",
    },
    roomNumber: 'Кабинет',
    copyFromPrevWeek: 'Скопировать расписание предыдущей недели',
    copyFromNextWeek: 'Скопировать расписание со следующей',
    moveDay: "Выберите день",
    moveDayItem: "Выберите урок"
  },
  videoLesson:{
    title: "Видео урок"
  },
  studentTimeTable:{
    day1: "Понедельник",
    day2: "Вторник",
    day3: "Среда",
    day4: "Четверг",
    day5: "Пятница",
    day6: "Суббота"
  },
  my:{
    school: "Моя школа",
    group: "Мой класс",
  },
  educationPlan:{
    title: 'КТП',
    name: 'Название',
    subjectName: 'Предмет',
    level: 'Для классов',
    eduHours: 'Учебные часы',
    teacherName: 'Преподаватель',
    subject: 'Предтем',
  },
  educationPlanItem:{
    theme: 'Тема',
    lessonContent: 'Что пройдено на уроке',
    educationHours: 'Учебные часы',
    homeWork: 'Домашняя работа',
    lessonType: 'Тип урока',
    competencies: 'Компетенции',
    literature: 'Литература',
    zun: 'Зун',
    note: 'Замечание',
    additionalMaterials: 'Дополнительные материалы',
    additionalMaterial:{
      type: "Тип",
      name: "Название",
      value: "Значение",
      file: "Файл",
    }
  },
  classroomTeacher:{
    title: 'Классное руководство'
  },
  subGroups:{
    name: 'Коротокое название',
    group: 'Класс',
    subject: 'Предмет',
    title: 'Подгруппы',
    new: 'Добавить новую подгруппу',
    teacher: 'Преподаватель',
    studentFullName: 'ФИО',
  },
  gradeBook:{
    title:'Журнал на неделю'
  },
  quarterMarks:{
    title:'Четвертные оценки',
    quarter:'Четверть',
    annualMark: 'Годовая оценка',
    examinationMarks: 'Экзаменационная оценка',
    finalMarks: 'Итоговая оценка',
  },
  schoolPages:{
    title:"Страницы",
    list:{
      type:"Тип",
      date:"Дата",
      titleKg:"Название на Кыргызском",
      titleRu:"Название на Русском",
      contentKg:"Содержание на Кыргызском",
      contentRu:"Содержание на Русском",
      mainImage:"Главная картинка"  
    }
  },
  certificates:{
    title:"Сертификаты",
    list:{
      id:"Id",
      image:"Изображение"
    }
  },
  studentInfo: {
    title: 'Данные студента',
    data:{
      inn:'ИНН',
      firstName:'Фамилия',
      name:'Имя',
      patronymic:'Отчество',
      birthDate:'Дата рождения',
      sex:'Пол',
      birthCertificateNumber:'Свидетельсво о рождении',
      citizenshipId:'Гражданство',
      socialPassportCategoryId:'Соц категория',
      nationalityId:'Национальность',
      livingAddress:'Адрес',
      phoneNumber:'Номер телефона',
      whatsApp:'WhatsApp',
      cartNumber:'Номер личного дела',
    },
    parents:{
      title:'Родители',
      list:{
        fullName: 'Имя',
        phoneNumber: 'Номер телефона',
        whatsAppNumber: 'whatsApp',
      },
      form:{
        inn:'ИНН',
        firstName :'Фамилия',
        name:'Имя',
        patronymic:'Отчество',
        address:'Адрес',
        sex:'Пол',
        phoneNumber:'Номер телефона',
        whatsAppNumber:'WhatsApp',
        workOrganizationName:'Место работы',
        workPosition:'Должность',
        workAddress:'Адрес места работы',
      }
    }
  },
  informationAboutBooks:{
    title:'СВЕДЕНИЯ ОБ УЧЕБНИКАХ',
    list:{
      name: 'Наименование учебников',
      firstHalfSuppliedBySchool: 'Кол-во учеб. получ. в школьной библиотеке',
      firstHalfSuppliedByParents: 'Кол-во учебни-ков приобретен-ных родителями и др',
      firstHalfSupplyRate: 'Обеспеченность в %',
      secondHalfSuppliedBySchool: 'Кол-во учеб. получ. в школьной библиотеке',
      secondHalfSuppliedByParents: 'Кол-во учебни-ков приобретен-ных родителями и др',
      secondHalfSupplyRate: 'Обеспеченность в %',
    },
  },
  socialUsefulWork:{
    title:'Общественно полезный труд',
    list:{
      name: 'ФИО',
      shortDescription: 'Краткое содержание выполненного общественнополезного труда',
      socUsefulWorkFirstHalf: 'I полугодие',
      socUsefulWorkSecondHalf: 'II полугодие',
      socUsefulWorkContent: 'Характер общественного поручения',
    },
  },
  informationAboutClassesInClub:{
    title:'Сведения о занятиях в кружках',
    list:{
      name: 'ФИО',
      clubs: 'Кружки (секции, клубы)',
      elective: 'Факультативы',
      clubNameFirstHalf: 'Наимен кружка (секции, клуба)',
      clubOrgNameFirstHalf: 'Название учреждения в котором он организован',
      clubNameSecondHalf: 'Наимен кружка (секции, клуба)',
      clubOrgNameSecondHalf: 'Название учреждения в котором он организован',
      electiveNameFirstHalf: 'Наименование факультативного курса',
      electiveNameSecondHalf: 'Наименование факультативного курса'
    },
  },
  informationAboutPhysicalActivityIndicators:{
    title:'Физическая активность',
    list:{
      name: 'ФИО',
      firstHalf: 'В начале учебного года (сентябрь)',
      secondHalf: 'В конце учебного года (май)',
      
      phyActIndicatorBeginRun: 'Бег 60 м',
      phyActIndicatorBeginPullUp: 'Подтягивание (мальчики), Поднимание туловища (девочки)',
      phyActIndicatorBeginStandingLongJump: 'Прыжок в длину с места (см)',
      phyActIndicatorBeginMark: 'Оценка состояния физической подготовки',

      phyActIndicatorEndRun: 'Бег 60 м',
      phyActIndicatorEndPullUp: 'Подтягивание (мальчики), Поднимание туловища (девочки)',
      phyActIndicatorEndStandingLongJump: 'Прыжок в длину с места (см)',
      phyActIndicatorEndMark: 'Оценка состояния физической подготовки',
    },
  },
  informationAboutHeath:{
    title:'Данные о состоянии здоровья школьника',
    list:{
      name: 'ФИО',
      birthDate: 'Год рождения',
      healthData: 'Данные о состоянии здоровья школьника',

      diagnosisOfTheDisease: 'Диагноз заболевания',
      treatmentRecommendations: 'Рекомендации лечения',
      vision: 'Зрение',
      hearing: 'Слух',
      medicalPhysicalEduGroup: 'Медфизкультурная группа',
      
      height: 'Рост (см)',
      deskNumber: 'Номер парты'
    },
  },
  summaryReportOfStudentsProgress: {
    title: "Сводная ведомость учета успеваемости учащихся",
    teachersCouncilDecision: "Решение педсовета (дата и номер)",
    quarter:{
      "1": "1 четверть",
      "2": "2 четверть",
      "3": "3 четверть",
      "4": "4 четверть",
      "5": "Годовая оценка",
      "6": "Экз. оценка",
      "7": "Итоговая оценка",
    }
  },
  groupFinalMarksCountReport: {
    title: "Сведения об итоговых оценках",
    teachersCouncilDecision: "Решение педсовета (дата и номер)",
    marks: "Оценки",
    quarter:{
      "1": "1 четверть",
      "2": "2 четверть",
      "3": "3 четверть",
      "4": "4 четверть",
      "5": "Годовая оценка",
      "6": "Экз. оценка",
      "7": "Итоговая оценка",
    }
  },
  groupInformationAboutStudentMovementReport: {
    title: "Сведения о движении учащихся",
    quarterStartCount: "Количество учащихся к началу четверти",
    quarterStartCountKyrgyz: "В том числе кыргызов",
    quarterStartCountGirls: "Из них девочек",
    
    leaveDuringQuarterCount: "Количество учащихся, выбывших в течении четверти",
    leaveDuringQuarterCountKyrgyz: "В том числе кыргызов",
    leaveDuringQuarterCountGirls: "Из них девочек",
    joinDuringQuarterCount: "Количество учащихся, принятых в течении четверти",
    joinDuringQuarterCountKyrgyz: "В том числе кыргызов",
    joinDuringQuarterCountGirls: "Из них девочек",
    
    quarterEndCount: "Количество учащихся к концу четверти",
    quarterEndCountKyrgyz: "В том числе кыргызов",
    quarterEndCountKyrgyzGirls: "Из них девочек",
    quarterEndCountBoys: "Всего мальчиков",
    quarterEndCountGirls: "Всего девочек",
    
    eduDaysCount: "Сколько дней занимался класс",
    
    quarter:{
      "1": "1 четверть",
      "2": "2 четверть",
      "3": "3 четверть",
      "4": "4 четверть",
      "yearly": "За год"
    },
    
  },
  informationAboutStudentLeaving: {
    title: "Сведения о причинах выбытия учащихся",
    
    leavingReason: "Причина выбытия",
    parentsMovement: "Переезд родителей",
    transfer: "Переведено",
    
    outsideTheRepublic: "За пределы республики",
    outsideTheOblast: "За пределы области, но в Кыргызской республике",
    outsideTheRaion: "За переделы района",
    insideTheRaion: "В пределах районе",
    school: "Общеобразовательные школы, дающие среднее образование",
    schoolTooFar: "Отдаленность от школы",
    work: "Поступили на работу",
    
    homeCircumstances: "Домашние обстоятельства",
    longTermIllness: "Длительная болезнь и смерть",
    expulsionFromSchool: "Исключение из школы",
    otherReasons: "Прочие причины",
    all: "Всего выбыло",
    
    quarter:{
      "1": "1 четверть",
      "2": "2 четверть",
      "3": "3 четверть",
      "4": "4 четверть",
      "yearly": "За год"
    },
    
  },
  /*classroom*/
  classrooms: {
    title: "Кабинетный фонд",
    common:{
      building: "Корпус",
      classroomNumber: "Номер кабинета",
      outOfService: "Не работает",
    }
  },
  /**/
  studentAbsenceNumberOfDays: {
    title: "Сведения о количестве дней и уроков, пропущенных учащимся",
    
    studentName: "Список учащихся",
    all: "Всего",
    
    fullDays: "Полных дней",
    fullDaysByIllness: "В т.ч. по болезни",
    
    lessons: "Уроков",
    lessonsByIllness: "В т.ч. по болезни",
    
    annual: "Всего за год",
    
    
    quarter:{
      "1": "1 четверть",
      "2": "2 четверть",
      "3": "3 четверть",
      "4": "4 четверть",
      "yearly": "Всего за год"
    },
    
    months:{
      "9": "Сентябрь",
      "10": "Октябрь",
      "11": "Ноябрь",
      "12": "Декабрь",
      "1": "Январь",
      "2": "Февраль",
      "3": "Март",
      "4": "Апрель",
      "5": "Май",
    }
  },
  /**/
  schoolTimeTable: {
    "title": "Шаблоны расписания",
    "list": {
      "name": "Название",
      "shift": "Смена",
    },
    "details": {},
    day: {
      1: "Пон",
      2: "Вт",
      3: "Ср",
      4: "Чет",
      5: "Пят",
      6: "Суб",
    },
    applyButton: "Применить",
    applyForm:{
      title: "Применить",
      date: "Дата",
    },
    clearButton: "Очистить"
  },
  educationProgram: {
    title: 'Учебная программа'
  },
  callSchedule:{
    types:{
      "first":"Первая смена",
      "second":"Вторая смена",
      "third":"Третья смена",
    }
  },
  absenceLogMonthlyReport:{
    "title": "Дисциплинарный дневник",
  }
};
